import Invert from 'lodash/invert'
import { generateId } from '@utils/id'
import { transformValueTypeForServer, transformValueType } from './value-type'
import {
  transformField,
  buildInputTypeForField,
  getAdditionalFieldProps,
} from './form'
import { transformApiField, transformApiFieldForServer } from '@data/form'
import {
  transformAnnouncement,
  transformAnnouncementForServer,
} from '@data/announcement'
import {
  transformRemoteDeploymentForDetail,
  transformRemoteDeploymentForServer,
} from '@data/remote-deployment'
import { dropdownTypeMap } from './qualification'
import {
  transformConfigurableKeywords,
  transformConfigurableKeywordsForServer,
} from '@data/print-template'

const actionMap = {
  email: 'EmailActionRest',
  sms: 'SmsActionRest',
  setValue: 'SetFieldActionRest',
  watcher: 'WatchersActionRest',
  plugin: 'PluginActionRest',
  approval: 'ApprovalWorkflowActionRest',
  approval_reminder: 'ApprovalReminderActionRest',
  priority_matrix: 'UpdatePriorityByPriorityMatrixActionRest',
  api: 'APIActionRest',
  relation: 'RelationActionRest',
  task: 'CreateTaskActionRest',
  addtask: 'TaskActionRest',
  custom_field: 'CustomFieldActionRest',
  smart_field: 'SmartFieldActionRest',
  reply_to_requester: 'ReplyToRequesterActionRest',
  announcement: 'AnnouncementActionRest',
  deploye_package: 'DeploymentActionRest',
  app: 'AppActionRest',
  teams: 'TeamsActionRest',
  whatsapp: 'WhatsAppActionRest',
  create_ticket: 'CreateTicketActionRest',
  conversation: 'ConversationActionRest',
  ticket_work: 'TicketWorkActionRest',
  cross_module: 'CrossModuleActionRest',
  note: 'NoteActionRest',
  sync_rule: 'ExecuteSyncRuleActionRest',
  generate_barcode: 'GenerateBarcodeActionRest',
}

function isTaskActionRest(action) {
  return action.type === 'TaskActionRest' || action.type === 'addtask'
}

function isEmailAction(action) {
  return action.type === 'EmailActionRest' || action.type === 'email'
}
function isCreateTaskAction(action) {
  return action.type === 'CreateTaskActionRest' || action.type === 'task'
}
function isSmsAction(action) {
  return action.type === 'SmsActionRest' || action.type === 'sms'
}

// eslint-disable-next-line
function isWatcherAction(action) {
  return action.type === 'WatchersActionRest' || action.type === 'watcher'
}

function isPluginAction(action) {
  return action.type === 'PluginActionRest' || action.type === 'plugin'
}

function isApiAction(action) {
  return action.type === 'APIActionRest' || action.type === 'api'
}

function isSmartFieldAction(action) {
  return action.type === 'SmartFieldActionRest' || action.type === 'smart_field'
}

function isValueAction(action) {
  return action.type === 'SetFieldActionRest' || action.type === 'setValue'
}

function isReplyAction(action) {
  return (
    action.type === 'ReplyToRequesterActionRest' ||
    action.type === 'reply_to_requester'
  )
}
function isAnnouncementAction(action) {
  return (
    action.type === 'AnnouncementActionRest' || action.type === 'announcement'
  )
}

function isPackageActionRest(action) {
  return (
    action.type === 'DeploymentActionRest' || action.type === 'deploye_package'
  )
}

function isAppAction(action) {
  return action.type === 'AppActionRest' || action.type === 'app'
}

function isTeamsAppAction(type) {
  return type === 'TeamsActionRest' || type === 'teams'
}

function isWhatsAppAppAction(type) {
  return type === 'WhatsAppActionRest' || type === 'whatsapp'
}

function isCreateTicketAction(action) {
  return (
    action.type === 'CreateTicketActionRest' || action.type === 'create_ticket'
  )
}

function isConversationAction(action) {
  return (
    action.type === 'ConversationActionRest' || action.type === 'conversation'
  )
}

function isTicketWorkAction(action) {
  return action.type === 'TicketWorkActionRest' || action.type === 'ticket_work'
}

function isCrossMoudleAction(action) {
  return (
    action.type === 'CrossModuleActionRest' || action.type === 'cross_module'
  )
}

function isNoteActionRest(action) {
  return action.type === 'NoteActionRest' || action.type === 'note'
}

function transformFieldActionTypeMap(data) {
  const obj = {}
  Object.keys(data || {}).map((key) => {
    obj[key] = (data[key] || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.setFieldActionType,
    }))
  })
  return obj
}

function transformFieldDetailsMap(data) {
  const obj = {}
  Object.keys(data || {}).map((key) => {
    obj[key] = (data[key] || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.referencedField,
    }))
  })
  return obj
}

export function transformAvailableAction(action) {
  const currentMap = Invert(actionMap)
  if (!currentMap[action.type]) {
    throw new Error(`${action.type} is not supported`)
  }
  let field
  if (action.field) {
    field = transformField(action.field)
  }
  return {
    value: action.fieldName,
    label: action.displayName,
    isCustomField: action.customField,
    type: currentMap[action.type],
    // inputType: action.inputTypes,
    inputType: dropdownTypeMap[action.inputTypes]
      ? 'dropdown'
      : action.inputTypes,
    ...(dropdownTypeMap[action.inputTypes]
      ? dropdownTypeMap[action.inputTypes]()
      : {}),
    valueType: action.primitiveType,
    ...(field
      ? {
          inputType: buildInputTypeForField(field),
          multiple: field.type === 'checkbox',
          valueType:
            field.type === 'number'
              ? field.attributes.allowDecimal
                ? 'double'
                : 'integer'
              : action.primitiveType,
          customFieldType: field.type,
          ...getAdditionalFieldProps(field),
        }
      : {}),
    fieldActionTypeOptions: (action.fieldActionTypeDetails || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.setFieldActionType,
    })),
    fieldDetailsOptions: (action.fieldDetails || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.referencedField,
    })),
    fieldActionTypeMap: transformFieldActionTypeMap(action.fieldActionTypeMap),
    fieldDetailsMap: transformFieldDetailsMap(action.fieldDetailsMap),
    appActionDetails: (action.appActionDetails || []).map((i) => ({
      appActionType: i.appActionType,
      actionDetails: (i.actionDetails || []).map((i) => ({
        isCustomField: i.customField,
        text: i.displayName,
        key: i.fieldName,
        type: currentMap[i.type],
      })),
    })),
    ...(currentMap[action.type] === 'cross_module'
      ? {
          crossModuleActionTypesOptions: (
            action.crossModuleActionTypes || []
          ).map((i) => ({
            ...i,
            text: i.displayName,
            key: i.crossModuleActionType,
          })),
        }
      : {}),
    associatedFromDetailsOptions: (action.associatedFromDetails || []).map(
      (i) => ({
        ...i,
        text: i.displayName,
        key: i.associatedFromType,
      })
    ),
    crossModuleAction: action.crossModuleAction || false,
  }
}

export function transformAction(action) {
  const currentMap = Invert(actionMap)
  if (!currentMap[action.type]) {
    throw new Error(`${action.type} is not supported`)
  }
  return {
    guid: generateId(),
    id: action.id,
    isCustomField: action.customField,
    ...(isValueAction(action) ? transformValueType(action.fieldValue) : {}),
    setFieldActionType: action.setFieldActionType,
    action: action.fieldName,
    actionType: currentMap[action.type],
    description: action.description,
    ...(isNoteActionRest(action)
      ? {
          noteName: action.noteName,
          noteDescription: action.noteDescription,
        }
      : {}),
    ...(isEmailAction(action)
      ? {
          technicianGroups:
            action.groupSetActionType === 'from'
              ? transformValueType(action.groupFieldValue).value
              : action.groupIds,
          emails:
            action.emailSetActionType === 'from'
              ? transformValueType(action.emailFieldValue).value
              : action.recepientEmails,
          ccEmails:
            action.ccEmailSetActionType === 'from'
              ? transformValueType(action.ccEmailFieldValue).value
              : action.ccEmails,
          subject: action.subject,
          content: action.emailTemplateContent,
          groupSetActionType: action.groupSetActionType,
          emailSetActionType: action.emailSetActionType,
          ccEmailSetActionType: action.ccEmailSetActionType,
          configurableKeywordForEmail: Object.keys(
            action.configurableKeywordForEmail || {}
          ).map((key) => {
            return transformConfigurableKeywords(
              key,
              (action.configurableKeywordForEmail || {})[key]
            )
          }),
        }
      : isCreateTaskAction(action)
      ? {
          subject: action.subject,
          technicianId: action.technicianId,
          taskTypeId: action.taskTypeId,
          statusId: action.statusId,
          priorityId: action.priorityId,
          groupId: action.groupId,
          dueBy: action.dueBy,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          taskDescription: action.taskDescription,
          estimatedTime: action.estimatedTime,
          estimatedTimeUnit: action.estimatedTimeUnit,
          associateParentEntity: action.associateParentEntity,
          fieldValueDetails: action.fieldValueDetails,
        }
      : isTaskActionRest(action)
      ? {
          subject: action.subject,
          taskTypeId: action.taskTypeId,
          startTime: action.startTime,
          endTime: action.endTime,
          statusId: action.statusId,
          priorityId: action.priorityId,
          estimatedTime: action.estimatedTime,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          estimatedTimeUnit: action.estimatedTimeUnit,
          taskDependencyStage: action.taskDependencyStage,
          taskDescription: action.taskDescription,
          fileAttachements: action.fileAttachements,
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
          technicianId:
            action.technicianSetActionType === 'from'
              ? (transformValueType(action.technicianFieldValue) || {}).value
              : action.technicianId,
          groupId:
            action.groupSetActionType === 'from'
              ? transformValueType(action.groupFieldValue).value
              : action.groupId,
          fieldValueDetails: action.fieldValueDetails,
        }
      : isSmsAction(action)
      ? {
          technicianGroups: action.groupIds,
          content: action.content,
          contactNumber: action.contactNumber,
        }
      : isWatcherAction(action)
      ? {
          technicians:
            action.technicianSetActionType === 'from'
              ? (transformValueType(action.technicianFieldValue) || {}).value
              : action.technicianIds,
          technicianGroups:
            action.groupSetActionType === 'from'
              ? transformValueType(action.groupFieldValue).value
              : action.technicianGroupIds,
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
        }
      : isPluginAction(action)
      ? {
          pluginIdentifier: action.pluginIdentifier,
          inputMapping: (action.inputMapping || []).map((m) => ({
            ...m,
            guid: generateId(),
          })),
          outputMapping: (action.outputMapping || []).map((m) => ({
            ...m,
            guid: generateId(),
          })),
        }
      : isApiAction(action)
      ? {
          ...transformApiField(action),
        }
      : isSmartFieldAction(action)
      ? {
          fields: action.fields,
        }
      : isReplyAction(action)
      ? {
          content: action.responseTemplateContent,
          fileAttachments: action.attachment,
        }
      : isAnnouncementAction(action)
      ? {
          ...transformAnnouncement(action),
        }
      : isPackageActionRest(action)
      ? {
          packageDeploymentFormData: {
            ...transformRemoteDeploymentForDetail(action),
          },
        }
      : isAppAction(action)
      ? {
          guid: generateId(),
          appActionType: action.appActionType,
          appAction: action.appAction,
          ...(isTeamsAppAction(action.appActionType)
            ? { teams: transformTeamsAppAction(action) }
            : {}),
          ...(isWhatsAppAppAction(action.appActionType)
            ? {
                whatsApp: transformWhatsAppAction(action),
              }
            : {}),
        }
      : isCreateTicketAction(action)
      ? {
          serviceCatalogId: action.serviceCatalogId,
        }
      : isConversationAction(action)
      ? {
          conversationType: action.conversationType,
          conversationFileAttachments: action.flotoAttachmentRestSet,
          conversationConsiderAsFirstResponse: action.noteAsFirstResponse,
          conversationCopyFromParent: action.copyFromParent,
          conversationDetails: action.conversationContent,
        }
      : isTicketWorkAction(action)
      ? {
          conversationType: action.ticketWorkType,
          conversationFileAttachments: action.fileAttachments,
          conversationCopyFromParent: action.copyFromParent,
          conversationDetails: action.workDetails,
        }
      : isCrossMoudleAction(action)
      ? {
          actions: action.actionRest.map(transformAction),
          crossModuleActionType: action.crossModuleActionType,
        }
      : {}),
  }
}

export function transformActionForServer(action) {
  if (!actionMap[action.actionType]) {
    throw new Error(`${action.actionType} is not supported`)
  }
  return {
    fieldName: action.action,
    ...(!isEmailAction({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(!isCreateTaskAction({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(!isSmsAction({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(!isTaskActionRest({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(isValueAction({ type: action.actionType })
      ? {
          fieldValue: transformValueTypeForServer(
            action.setFieldActionType === 'from' ||
              action.setFieldActionType === 'same_as'
              ? 'field'
              : action.valueType,
            action.value
          ).value,
          setFieldActionType: action.setFieldActionType,
          ...(action.action.split('.').length > 1
            ? {
                childModel: action.action.split('.')[0],
              }
            : {
                childModel: null,
              }),
        }
      : {}),
    ...(isNoteActionRest({ type: action.actionType })
      ? {
          noteName: action.noteName,
          noteDescription: action.noteDescription,
        }
      : {}),
    ...(action.id ? { id: action.id } : {}),
    type: actionMap[action.actionType],
    description: action.description,
    ...(action.actionType === 'email'
      ? {
          enable: true,
          subject: action.subject,
          emailTemplateContent: action.content,
          groupSetActionType: action.groupSetActionType,
          ...(action.groupSetActionType === 'from'
            ? {
                groupFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicianGroups
                ).value,
              }
            : {
                groupIds: action.technicianGroups,
              }),
          emailSetActionType: action.emailSetActionType,
          ...(action.emailSetActionType === 'from'
            ? {
                emailFieldValue: transformValueTypeForServer(
                  'field',
                  action.emails
                ).value,
              }
            : {
                recepientEmails: action.emails,
              }),
          ccEmailSetActionType: action.ccEmailSetActionType,
          ...(action.ccEmailSetActionType === 'from'
            ? {
                ccEmailFieldValue: transformValueTypeForServer(
                  'field',
                  action.ccEmails
                ).value,
              }
            : {
                ccEmails: action.ccEmails,
              }),
          configurableKeywordForEmail: transformConfigurableKeywordsForServer(
            action.configurableKeywordForEmail || {}
          ),
        }
      : action.actionType === 'task'
      ? {
          subject: action.subject,
          technicianId: action.technicianId,
          taskTypeId: action.taskTypeId,
          statusId: action.statusId,
          priorityId: action.priorityId,
          groupId: action.groupId,
          dueBy: action.dueBy,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          taskDescription: action.taskDescription,
          estimatedTime: action.estimatedTime,
          estimatedTimeUnit: action.estimatedTimeUnit,
          associateParentEntity: action.associateParentEntity,
          fieldValueDetails: action.fieldValueDetails,
        }
      : action.actionType === 'sms'
      ? {
          groupIds: action.technicianGroups,
          content: action.content,
          contactNumber: action.contactNumber,
        }
      : action.actionType === 'addtask'
      ? {
          subject: action.subject,
          taskTypeId: action.taskTypeId,
          startTime: action.startTime,
          endTime: action.endTime,
          statusId: action.statusId,
          priorityId: action.priorityId,
          estimatedTime: action.estimatedTime,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          estimatedTimeUnit: action.estimatedTimeUnit,
          taskDependencyStage: action.taskDependencyStage,
          taskDescription: action.taskDescription,
          fileAttachements: action.fileAttachements,
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
          ...(action.groupSetActionType === 'from'
            ? {
                groupFieldValue: transformValueTypeForServer(
                  'field',
                  action.groupId
                ).value,
              }
            : {
                groupId: action.groupId,
              }),
          ...(action.technicianSetActionType === 'from'
            ? {
                technicianFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicianId
                ).value,
              }
            : {
                technicianId: action.technicianId,
              }),
          fieldValueDetails: action.fieldValueDetails,
        }
      : action.actionType === 'watcher'
      ? {
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
          ...(action.groupSetActionType === 'from'
            ? {
                groupFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicianGroups
                ).value,
              }
            : {
                technicianGroupIds: action.technicianGroups,
              }),
          ...(action.technicianSetActionType === 'from'
            ? {
                technicianFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicians
                ).value,
              }
            : {
                technicianIds: action.technicians,
              }),
        }
      : action.actionType === 'plugin'
      ? {
          pluginIdentifier: action.pluginIdentifier,
          inputMapping: action.inputMapping,
          outputMapping: action.outputMapping,
        }
      : action.actionType === 'api'
      ? {
          ...transformApiFieldForServer(action),
        }
      : action.actionType === 'smart_field'
      ? {
          fields: action.fields,
        }
      : action.actionType === 'reply_to_requester'
      ? {
          responseTemplateContent: action.content,
          attachment: action.fileAttachments,
        }
      : action.actionType === 'announcement'
      ? {
          ...transformAnnouncementForServer(action),
        }
      : action.actionType === 'deploye_package'
      ? {
          ...transformRemoteDeploymentForServer(
            action.packageDeploymentFormData
          ),
        }
      : action.actionType === 'app'
      ? {
          enable: true,
          appActionType: action.appActionType,
          appAction: action.appAction,
          ...(isTeamsAppAction(action.appActionType)
            ? { teams: transformTeamsAppActionForServer(action) }
            : {}),
          ...(isWhatsAppAppAction(action.appActionType)
            ? { whatsApp: transformWhatsAppAction(action) }
            : {}),
        }
      : action.actionType === 'create_ticket'
      ? {
          serviceCatalogId: action.serviceCatalogId,
        }
      : action.actionType === 'conversation'
      ? {
          conversationType: action.conversationType,
          flotoAttachmentRestSet: action.conversationFileAttachments,
          noteAsFirstResponse: action.conversationConsiderAsFirstResponse,
          copyFromParent: action.conversationCopyFromParent,
          conversationContent: action.conversationDetails,
        }
      : action.actionType === 'ticket_work'
      ? {
          ticketWorkType: action.conversationType,
          fileAttachments: action.conversationFileAttachments,
          copyFromParent: action.conversationCopyFromParent,
          workDetails: action.conversationDetails,
        }
      : action.actionType === 'cross_module'
      ? {
          actionRest: action.actions.map(transformActionForServer),
          crossModuleActionType: action.crossModuleActionType,
        }
      : {}),
  }
}

export function transformTeamsAppAction(action) {
  return {
    notifyEmails: (action.teams || {}).notifyEmails || [],
    groupIds: (action.teams || {}).groupIds || [],
    teamsContent: (action.teams || {}).teamsContent || '',
    configurableKeywordForTeams: Object.keys(
      (action.teams || {}).configurableKeywordForTeams || {}
    ).map((key) => {
      return transformConfigurableKeywords(
        key,
        ((action.teams || {}).configurableKeywordForTeams || {})[key]
      )
    }),
  }
}

export function transformTeamsAppActionForServer(action) {
  return {
    notifyEmails: (action.teams || {}).notifyEmails || [],
    groupIds: (action.teams || {}).groupIds || [],
    teamsContent: (action.teams || {}).teamsContent || '',
    configurableKeywordForTeams: transformConfigurableKeywordsForServer(
      (action.teams || {}).configurableKeywordForTeams || {}
    ),
  }
}

export function transformWhatsAppAction(action) {
  return {
    notifyNumbers: (action.whatsApp || {}).notifyNumbers || [],
    groupIds: (action.whatsApp || {}).groupIds || [],
    technicianIds: (action.whatsApp || {}).technicianIds || [],
    whatsAppContent: (action.whatsApp || {}).whatsAppContent || '',
  }
}
