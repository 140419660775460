import { generateId } from '@utils/id'

export function transformConfigurableKeywords(keyword, data) {
  return {
    guid: generateId(),
    keyword,
    fields: data,
  }
}

export function transformConfigurableKeywordsForServer(keywordsList) {
  if (!keywordsList.length) {
    return {}
  }
  const obj = {}
  keywordsList.forEach((i) => {
    obj[i.keyword] = i.fields || []
  })
  return obj
}

export function transformPrintTemplate(template) {
  return {
    id: template.id,
    name: template.name,
    content: template.printFormat || '',
    primary: template.isDefault,
    enabled: template.enabled,
    moduleName: template.model,
    description: template.description,
    canDelete: template.deleteableOob,
    canEdit: template.updatebleOob,
    parentId: template.refId,
    assetTypeId: template.assetTypeId,
    movementType: template.movementType,
    configurableKeywordList: Object.keys(
      template.configurableKeyword || {}
    ).map((key) => {
      return transformConfigurableKeywords(
        key,
        (template.configurableKeyword || {})[key]
      )
    }),
  }
}

export function transformPrintTemplateForServer(template) {
  return {
    name: template.name,
    printFormat: template.content || '',
    isDefault: template.primary,
    enabled: template.enabled,
    description: template.description,
    ...(template.assetTypeId ? { assetTypeId: template.assetTypeId } : {}),
    ...(template.movementType ? { movementType: template.movementType } : {}),
    configurableKeyword: transformConfigurableKeywordsForServer(
      template.configurableKeywordList || {}
    ),
  }
}
