export function transformDiscoveryAgents(discoveryAgent) {
  return {
    id: discoveryAgent.id,
    name: discoveryAgent.name,
    hostName: discoveryAgent.hostName,
    ipAddress: discoveryAgent.ipAddress,
    pollerId: discoveryAgent.pollerId,
    osName: discoveryAgent.osName,
    agentVersion: discoveryAgent.agentVersion,
    domainName: discoveryAgent.domainName,
    lastRefreshCallTime: discoveryAgent.lastRefreshCallTime,
    active: discoveryAgent.active,
    agentActiveStatus: discoveryAgent.agentActiveStatus,
    refreshCycle: discoveryAgent.refreshCycle,
    discoveryCycle: discoveryAgent.discoveryCycle,
    meterCycle: discoveryAgent.meterCycle,
    patchCycle: discoveryAgent.patchCycle,
    allowAgentToDownloadFileFromInternet:
      discoveryAgent.allowAgentToDownloadFileFromInternet,
    meterDataCycle: discoveryAgent.meterDataCycle,
    redhatRepoSyncCycle: discoveryAgent.redhatRepoSyncCycle,
    usageCount: discoveryAgent.usageCount,
    usageDuration: discoveryAgent.usageDuration,
    lastUsedTime: discoveryAgent.lastUsedTime,
    servicePack: discoveryAgent.servicePack,
    remoteOfficeId: discoveryAgent.remoteOfficeId,
    architecture: discoveryAgent.architecture,
    archived: discoveryAgent.removed,
    credentialProfileId: discoveryAgent.credentialProfileId,
    credentialProfileName: discoveryAgent.credentialProfileName,
  }
}
export function transformAgentSetting(discoveryAgent) {
  return {
    id: discoveryAgent.id,
    refreshCycle: discoveryAgent.refreshCycle,
    discoveryCycle: discoveryAgent.discoveryCycle,
    meterCycle: discoveryAgent.meterCycle,
    meterDataCycle: discoveryAgent.meterDataCycle,
    redhatRepoSyncCycle: discoveryAgent.redhatRepoSyncCycle,
    patchCycle: discoveryAgent.patchCycle,
    optForMoreSecureCommunication: discoveryAgent.optForMoreSecureCommunication,
    allowAgentToDownloadFileFromInternet:
      discoveryAgent.allowAgentToDownloadFileFromInternet,
    enableDownloadedPatchReplicationOnFileServer:
      discoveryAgent.enableDownloadedPatchReplicationOnFileServer,
  }
}
export function transformAgentSettingForServer(discoveryAgent) {
  return {
    id: discoveryAgent.id,
    refreshCycle: discoveryAgent.refreshCycle,
    discoveryCycle: discoveryAgent.discoveryCycle,
    meterCycle: discoveryAgent.meterCycle,
    meterDataCycle: discoveryAgent.meterDataCycle,
    patchCycle: discoveryAgent.patchCycle,
    redhatRepoSyncCycle: discoveryAgent.redhatRepoSyncCycle,
    allowAgentToDownloadFileFromInternet:
      discoveryAgent.allowAgentToDownloadFileFromInternet,
    enableDownloadedPatchReplicationOnFileServer:
      discoveryAgent.enableDownloadedPatchReplicationOnFileServer,
  }
}
export function transformMobileAgentSetting(mobileAgent) {
  return {
    enabled: mobileAgent.enabled,
    intervalTime: mobileAgent.intervalTime,
    barcode: mobileAgent.barcode,
    discoveryCycle: mobileAgent.discoveryCycle,
    refreshCycle: mobileAgent.refreshCycle,
  }
}
export function transformMobileAgentSettingForServer(mobileAgent) {
  return {
    enabled: mobileAgent.enabled,
    intervalTime: mobileAgent.intervalTime,
    barcode: mobileAgent.barcode,
    discoveryCycle: mobileAgent.discoveryCycle,
    refreshCycle: mobileAgent.refreshCycle,
  }
}

export function tranformCrendetialProfile(credentialProfile) {
  return {
    id: credentialProfile.id,
    name: credentialProfile.name,
    disabled: credentialProfile.disabled,
    description: credentialProfile.description,
    basicAuth: credentialProfile.basicAuth,
    archived: credentialProfile.removed,
    canDelete: credentialProfile.deleteableOob,
  }
}

export function transformCreateCredentialProfileForServer(data) {
  return {
    name: data.name,
    description: data.description,
    ...(data.id && { id: data.id }),
  }
}

export function transformDiscoveryPollerConfig(discoveryPollerConfig) {
  return {
    id: discoveryPollerConfig.id,
    name: discoveryPollerConfig.name,
    hostName: discoveryPollerConfig.hostName,
    ipAddress: discoveryPollerConfig.ipAddress,
    osName: discoveryPollerConfig.osName,
    pollerVersion: discoveryPollerConfig.pollerVersion,
    lastRefreshCallTime: discoveryPollerConfig.lastRefreshCallTime,
    active: discoveryPollerConfig.active,
    credentialProfileId: discoveryPollerConfig.credentialProfileId,
    credentialProfileName: discoveryPollerConfig.credentialProfileName,
    nmapEnabled: discoveryPollerConfig.nmapEnabled,
    nmapVersion: discoveryPollerConfig.nmapVersion,
  }
}
export function transformDiscoveryPollerConfigSetting(discoveryPoller) {
  return {
    refreshCycle: discoveryPoller.refreshCycle,
  }
}
export function transformDiscoveryPollerConfigSettingForServer(
  discoveryPoller
) {
  return {
    refreshCycle: discoveryPoller.refreshCycle,
  }
}
export function transformAgentLogRequest(discoveryAgent) {
  return {
    id: discoveryAgent.id,
    updatedById: discoveryAgent.updatedById,
    updatedTime: discoveryAgent.updatedTime,
    syncResource: discoveryAgent.syncResource,
    createdById: discoveryAgent.createdById,
    createdTime: discoveryAgent.createdTime,
    removed: discoveryAgent.removed,
    inActive: discoveryAgent.inActive,
    updatebleOob: discoveryAgent.updatebleOob,
    deleteableOob: discoveryAgent.deleteableOob,
    refModel: discoveryAgent.refModel,
    refId: discoveryAgent.refId,
    state: discoveryAgent.state,
    fileExists: discoveryAgent.fileExists,
  }
}
